<template>
  <div style="display: flex; height: 100%" @keydown.enter="loginUser">
    <v-row class="main-row" style="background-color: white">
      <v-col class="login-col">
        <v-col class="form-col">
          <div class="header-row">
            <v-row class="title-row">
              <h1 class="login-title">{{ $t("Login") }}</h1>
            </v-row>
            <BaseInput
              v-model="email"
              :placeholder="$t('FirstName')"
              icon-left="email.svg"
              id="email-input"
              :spaces-removal="true"
            />
            <BaseInput
              v-model="password"
              :placeholder="$t('Password')"
              type="password"
              icon-left="lock.svg"
              id="password-input"
              style="margin-top: 8px"
            />
            <v-row class="login-row">
              <button
                @click="loginUser"
                name="login-button"
                class="login-button content-semibold"
              >
                {{ $t("Login") }}
              </button>
            </v-row>
            <v-row v-if="responseMessage" class="error-row">
              <p>{{ responseMessage }}</p>
            </v-row>
          </div>
        </v-col>
        <!--    <p style="position: absolute; bottom: 15px; left: 12px; opacity: 0.2">
          ver. {{ formatVersion(buildVersion) }}
        </p> -->
      </v-col>
      <!--  <v-col
        v-bind:class="{
          'image-col': !isUusMaa,
          'image-col-uusmaa': isUusMaa,
        }"
      >
        <img
          class="uusmaa-logo"
          v-if="isUusMaa"
          src="https://vanemuise60.ee/wp-content/uploads/2021/04/Uus_Maa_logo_RGB-02.png"
          alt=""
        />
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseInput from "@/components/common/BaseInput";

export default {
  name: "SelfServiceLogin",
  components: {
    BaseInput,
  },
  data() {
    return {
      email: "",
      password: "",
      authError: null,
      tryingToLogIn: false,
      responseMessage: null,
    };
  },
  created() {
    if (this.$route.params.email && this.$route.params.password) {
      this.email = this.$route.params.email;
      this.password = this.$route.params.password;
      this.loginUser();
    }
  },
  computed: {
    ...mapGetters(["user", "buildVersion"]),
    /* isUusMaa() {
      return window.location.hostname === "uusmaa.runproperty.com";
    }, */
    placeholders() {
      return process.env.NODE_ENV === "production"
        ? {}
        : {
            username: 'Use "admin" to log in with the mock API',
            password: 'Use "password" to log in with the mock API',
          };
    },
  },
  methods: {
    ...mapActions(["loginSelfUser", "getCustomers"]),
    async loginUser() {
      const selfUser = {
        email: this.email,
        password: this.password,
      };

      let respo = await this.loginSelfUser(selfUser);
      if (respo.status !== 200) {
        this.responseMessage = "Login failed";
        setTimeout(() => {
          this.responseMessage = null;
        }, 3000);
      } else {
        await this.$router.push({
          name: "SelfServiceBoard",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-row {
  display: flex;
  justify-content: center;
  width: 100%;
}
/* .header-row {
  width: 54%;
  min-width: 200px;
  margin: 0 auto;
} */

.login-col {
  display: flex;
  margin-top: 200px;
  max-width: 50%;
  justify-content: center;
}
.login-row {
  margin-top: 16px !important;
}
.password-row {
  margin-top: 8px !important;
}
.form-col {
  max-width: 300px;
}

.title-row {
  margin-bottom: 48px;
}

.login-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  font-family: "Geomanist", sans-serif !important;
}

.email-row {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.login-button {
  text-transform: none;
  width: 100%;
  padding: 12px 16px;
  background-color: black !important;
  color: white !important;
  border-radius: 12px;
  text-align: center !important;
}
.error-row {
  display: flex;
  padding: 16px;
  max-height: fit-content;
  margin-top: 16px;
  background-color: rgba(255, 0, 0, 0.4);
}
.logo-wrapper {
  cursor: pointer;
}
.logo {
  max-width: 128px;
  margin-top: 64px;
}

.image-col {
  border-radius: 12px;
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/loginImage.svg");
  background-position: center bottom;
}
.image-col-uusmaa {
  border-radius: 12px;
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url(https://uusmaa.ee/wp-content/uploads/2019/08/AdobeStock_271403301-1-1024x674.jpeg);
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}
.uusmaa-logo {
  position: absolute;
  top: 100px;
}
@media screen and (max-width: 900px) {
  .image-col {
    display: none;
  }
}
.title-row {
  padding-left: 0;
}
</style>
